import TextField from "@mui/material/TextField";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { City } from "../../types";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { isTypeCity } from "../../logic/helper";

const standardize = (str: string) => str.toLowerCase().replace(/[^a-z]/g, "");

const getNotFoundMsg = (customValue: string, optionalAdd: boolean): string => {
  let notFoundText = customValue + " not found.";
  notFoundText += optionalAdd
    ? "Add it to the map?"
    : "Have you tried the next closest big city?";
  return notFoundText;
};

const CityInput = ({
  cities,
  addNewCity,
  autoSelect = false,
  onSelect,
  instructions,
}: {
  cities: City[];
  addNewCity?: (rawCity: string, onSuccess: (newCity: City) => void) => void;
  autoSelect?: boolean;
  onSelect: (city: City | string | undefined) => void;
  instructions?: string;
}) => {
  const optionalAdd = addNewCity != null;

  const formatCity = (city: City) => city.City + ", " + city.Country;

  const [value, setValue] = useState<City | null>(null);

  const [customValue, setCustomValue] = useState<string | null>(null);

  const [loading, setLoading] = useState<string | null>(null);

  useEffect(() => {
    onSelect(customValue || value || undefined);
  }, [customValue, value, onSelect]);

  if (cities.length === 0) return <></>
  
  return (
    <>
      <FormControl fullWidth sx={{ flexBasis: "100%" }}>
        <Autocomplete
          freeSolo
          disablePortal
          fullWidth
          onChange={(event, newValue, reason) => {
            switch (reason) {
              case "selectOption":
                setValue(newValue as City);
                setCustomValue(null);
                break;
              case "createOption":
                setCustomValue(newValue as string);
                setValue(null);
                break;
              case "clear":
                console.log("CLEAR");
                setCustomValue(null);
                setValue(null);
                break;
              default:
                let city = isTypeCity(newValue)
                  ? (newValue as City)
                  : typeof newValue === "string"
                    ? cities.find((city) => formatCity(city) === newValue)
                    : null;

                if (!city) {
                  setCustomValue(newValue as string);
                  setValue(null);
                } else {
                  setValue(city);
                  setCustomValue(null);
                }
            }
          }}
          value={customValue || value}
          autoHighlight={autoSelect}
          autoSelect={true}
          options={cities}
          selectOnFocus
          getOptionLabel={(option) => {
            return option == null
              ? ""
              : typeof option === "string"
                ? option
                : formatCity(option as City);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City, Country"
              placeholder={formatCity(cities[0])}
            />
          )}
          filterOptions={(options, state) => {
            const inputValue = standardize(state.inputValue);
            const filteredOptions = inputValue
              ? options.filter((option) =>
                  standardize(formatCity(option)).includes(inputValue),
                )
              : options;
            return filteredOptions;
          }}
        />
        {instructions && <FormHelperText>{instructions}</FormHelperText>}
        {customValue && (
          <FormHelperText error={true}>
            {getNotFoundMsg(customValue, optionalAdd)}
          </FormHelperText>
        )}
      </FormControl>
      {customValue && optionalAdd && (
        <Button
          variant="text"
          sx={{ textAlign: "left" }}
          startIcon={<SearchIcon />}
          disabled={loading === customValue}
          onClick={() => {
            setLoading(customValue);
            addNewCity(customValue, (city) => {
              setLoading(null);
              setCustomValue(null);
              setValue(city);
            });
          }}
        >
          Add {customValue} to map?
        </Button>
      )}
      {customValue && loading === customValue && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 3,
            flexDirection: "row",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CityInput;
