import { post, getCityId, getCityResponseId } from "./api";
import {
  CityGeoWithResponseScored,
  CityResponseScored,
  UserInfo,
  CityGeo,
  Updaters,
  FormStatus,
} from "../types";
import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { FormError, VersionError } from "../types";
import { MOCK_DATA, PROD } from "./constant";

let init_cityWithScores: CityResponseScored[] = [];
let init_cityGeos: CityGeo[] = [];

if (!PROD && MOCK_DATA) {
  const mockData = require("../tests/mock-data");
  const { EXAMPLE_CITIES } = mockData;
  init_cityWithScores = EXAMPLE_CITIES;
  init_cityGeos = EXAMPLE_CITIES;
}

const getLatLngMapper = (
  cities: CityGeo[],
): { [key: string]: [number, number] } => {
  return cities.reduce(
    (mapper, city) => {
      let { Latitude, Longitude } = city;

      let id = getCityId(city);

      mapper[id] = [Latitude, Longitude];

      return mapper;
    },
    {} as { [key: string]: [number, number] },
  );
};

const useSurveyCity = ({
  question,
  answer,
  user,
  setAnswer,
  setQuestion,
  setUpdateFormStatus,
}: {
  question: string;
  answer: string;
  user: UserInfo;
  setUpdateFormStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
  setAnswer: (answer: string) => void;
  setQuestion: (question: string) => void;
}): [CityGeoWithResponseScored[], Updaters] => {
  const [cityWithScores, setCitiesWithScores] =
    useState<CityResponseScored[]>(init_cityWithScores);

  const [cityGeos, setCityGeos] = useState<CityGeo[]>(init_cityGeos);

  const version = useRef(0);

  const updating = useRef(false);

  useEffect(() => {
    updating.current = false;
  }, [question, answer, cityWithScores]);

  useEffect(() => {
    setUpdateFormStatus((stat) =>
      stat === FormStatus.LOADING ? FormStatus.INACTIVE : stat,
    );
  }, [answer, question, setUpdateFormStatus]);

  const updateQuestion = async (newQuestion: string, newAnswer: string) => {
    if (updating.current) {
      alert("Already have a request in progress - try again later");
      return;
    }
    updating.current = true;
    version.current += 1;

    post("/city?include_geo=false", {
      question: { question: newQuestion, answer: newAnswer },
      user,
      cities: cityGeos,
    })
      .then((cities) => {
        console.log("Cities returned", cities.length, cities);
        setCitiesWithScores(cities as CityResponseScored[]);
        setQuestion(newQuestion);
        setAnswer(newAnswer);
      })
      .catch((err) => {
        updating.current = false;
        throw new FormError(
          (err as Error).message,
          "Failed to update question",
        );
      });
  };

  const updateAnswer = async (newAnswer: string) => {
    if (updating.current) {
      alert("Already have a request in progress - try again later");
      return;
    }
    updating.current = true;
    version.current += 1;
    post("/city/answer", {
      question: { question, answer: newAnswer },
      cities: cityWithScores,
    })
      .then((cities) => {
        setCitiesWithScores(cities as CityResponseScored[]);
        setAnswer(newAnswer);
      })
      .catch((err) => {
        updating.current = false;
        throw new FormError((err as Error).message, "Failed to update answer");
      });
  };

  const addCity = async (
    rawCity: string,
  ): Promise<CityGeoWithResponseScored> => {
    if (updating.current) {
      throw new FormError(
        "Update in progress",
        "Already have a request in progress - try again later",
      );
    }
    updating.current = true;
    try {
      const res = (await post("/city?include_geo=true", {
        question: { question, answer },
        user,
        cities: [rawCity],
      })) as CityGeoWithResponseScored[];

      const { City, Country, Response, similarity, Latitude, Longitude } =
        res?.[0] as CityGeoWithResponseScored;

      if (
        !cityGeos.map(getCityId).some((c) => c === getCityId({ City, Country }))
      ) {
        setCityGeos((c) => c.concat([{ City, Country, Latitude, Longitude }]));
      }
      setCitiesWithScores((c) =>
        c.concat([{ City, Country, Response, similarity }]),
      );

      return {
        City,
        Country,
        Response,
        similarity,
        Latitude,
        Longitude,
      } as CityGeoWithResponseScored;
    } catch (error) {
      updating.current = false;
      throw new FormError(
        (error as Error).message,
        "Failed to add city " + rawCity,
      );
    }
  };

  const addCities = useCallback(async () => {
    const currentVersion = version.current;
    try {
      const cities = (await post("/city?include_geo=true", {
        question: { question, answer },
        user,
      })) as CityGeoWithResponseScored[];

      if (currentVersion !== version.current) {
        throw new VersionError("Version changed");
      }

      setCitiesWithScores((existing) => {
        const existingIds = existing.map(getCityResponseId);
        const newCities = cities
          .filter((city) => !existingIds.includes(getCityResponseId(city)))
          .map(({ Response, similarity, City, Country }) => ({
            Response,
            similarity,
            City,
            Country,
          }));
        return existing.concat(newCities);
      });

      setCityGeos((existing) => {
        const existingIds = existing.map(getCityId);
        const newCities = cities
          .filter((city) => !existingIds.includes(getCityId(city)))
          .map(({ Latitude, Longitude, City, Country }) => ({
            Latitude,
            Longitude,
            City,
            Country,
          }));
        return existing.concat(newCities);
      });
    } catch (error) {
      if (error instanceof VersionError) {
        throw error; // Rethrow VersionError
      }
      console.error("Error adding cities:", error);
    }
  }, [question, answer, user]);

  // merge
  const mapper = useMemo(() => getLatLngMapper(cityGeos), [cityGeos]);
  const cityGeoWithScore = useMemo(
    () =>
      cityWithScores
        .filter((city) => getCityId(city) in mapper)
        .map((city) => ({
          ...city,
          Latitude: mapper[getCityId(city)][0],
          Longitude: mapper[getCityId(city)][1],
        })) as CityGeoWithResponseScored[],
    [cityWithScores, mapper],
  );

  return [
    cityGeoWithScore,
    { updateAnswer, updateQuestion, addCity, addCities },
  ];
};

export default useSurveyCity;
