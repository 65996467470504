import { ScaleSequential, ScaleDiverging } from "d3";
import { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import HandwrittenFadingText from "./FadeText";
import { CityGeoWithResponseScored } from "../../types";
import { randomInt } from "../../logic/helper";
import { Anchor } from "../../types";
import useViewProperties from "../../styles/useViewProperties";

const CityMarker = ({
  city,
  color,
  selected,
  x,
  y,
  r,
}: {
  city: CityGeoWithResponseScored;
  color: ScaleSequential<string, never> | ScaleDiverging<string>;
  selected: boolean;
  x: number;
  y: number;
  r: number;
}) => {
  const { isTouchDevice, isSmallHeight } = useViewProperties();
  const [active, setActive] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const circleRef = useRef<SVGCircleElement>(null);

  const lowBound = 100;
  const highBound = 2000;
  useEffect(() => {
    const timer = setTimeout(
      () => setFadeIn(true),
      randomInt(lowBound, highBound),
    );
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (selected) {
      setFadeIn(true);
    }
  }, [selected]);

  const { left, top } = circleRef.current?.getBoundingClientRect() ?? {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  };

  // get page coordinates
  const pageX = left + window.scrollX;
  const pageY = top + window.scrollY;

  // if bottom half - use bottom
  const bottomHalf = top > window.innerHeight / 2;
  const rightHalf = left > window.innerWidth / 2;

  const anchor =
    rightHalf && bottomHalf
      ? Anchor.BOTTOM_RIGHT
      : rightHalf
        ? Anchor.TOP_RIGHT
        : bottomHalf
          ? Anchor.BOTTOM_LEFT
          : Anchor.TOP_LEFT;

  const jitter = useRef({
    x: Math.random() * 2 * r - r,
    y: Math.random() * 2 * r - r,
  }).current;

  const cx = x + jitter.x;
  const cy = y + jitter.y;

  useEffect(() => {
    if (!circleRef.current || !isTouchDevice) return;

    const handleTouchStart = (event: TouchEvent) => {
      event.preventDefault();
      setActive(true);
    };

    const circle = circleRef.current;

    circle.addEventListener("touchstart", handleTouchStart, { passive: false });

    return () => {
      circle.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <>
      <circle
        ref={circleRef}
        cx={cx}
        cy={cy}
        r={active || clicked || selected ? Math.max(r * 1.5, 5) : r}
        fill={color(city.similarity)}
        {...(!isTouchDevice
          ? {
              onMouseEnter: () => setActive(true),
              onMouseLeave: () => {
                setActive(false);
                setClicked(false);
              },
              onClick: () => setActive(true),
            }
          : {
              onTouchEnd: () => setActive(false),
            })}
        stroke={selected || clicked || active ? "yellow" : "transparent"}
        strokeWidth={2}
        style={{
          opacity: fadeIn ? 1 : 0,
          cursor: "pointer",
          transition: "opacity 0.5s ease-in-out, fill 2s ease-in-out",
        }}
      />

      {(active || clicked || (!isSmallHeight && selected)) &&
        createPortal(
          <HandwrittenFadingText
            x={pageX}
            y={pageY}
            anchor={anchor}
            selected={clicked}
            text={`${city.City}: ${city.Response}`}
          />,
          document.body,
        )}
    </>
  );
};

export default CityMarker;
