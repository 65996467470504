import { Gender, UserInfo } from "../types";

export const EXAMPLE_USER_INFO: UserInfo = {
  age: 30,
  gender: Gender.Male,
  city: {
    City: "Boston",
    Country: "USA",
  },
};

export const EXAMPLE_CITIES = [
  {
    City: "Boston",
    Country: "USA",
    Latitude: 42.3589,
    Longitude: -71.0594,
    similarity: 2.1,
    Response: "I like to ski",
  },
  {
    City: "Berlin",
    Country: "Germany",
    Latitude: 52.52,
    Longitude: 13.405,
    similarity: 2,
    Response:
      "You can find me dancing the night away in Kreuzberg. Or trying out new brunch spots in Neukolin.",
  },
  {
    City: "Paris",
    Country: "France",
    Latitude: 48.8566,
    Longitude: 2.3522,
    similarity: 1.2,
    Response: "I like to dance.",
  },
  {
    City: "Tokyo",
    Country: "Japan",
    Latitude: 35.6895,
    Longitude: 139.6917,
    similarity: 1.8,
    Response:
      "Exploring the bustling Shibuya district or enjoying quiet moments at a Shinto shrine.",
  },
  {
    City: "Sydney",
    Country: "Australia",
    Latitude: -33.8688,
    Longitude: 151.2093,
    similarity: 2.3,
    Response: "Surfing at Bondi Beach and enjoying outdoor barbecues.",
  },
  {
    City: "São Paulo",
    Country: "Brazil",
    Latitude: -23.5505,
    Longitude: -46.6333,
    similarity: 1.5,
    Response:
      "Dancing samba at a local club or enjoying some street art in Beco do Batman.",
  },
  {
    City: "Cape Town",
    Country: "South Africa",
    Latitude: -33.9249,
    Longitude: 18.4241,
    similarity: 2.0,
    Response: "Hiking up Table Mountain and exploring local markets.",
  },
  {
    City: "New York",
    Country: "USA",
    Latitude: 40.7128,
    Longitude: -74.006,
    similarity: 2.4,
    Response: "Catching a Broadway show or wandering through Central Park.",
  },
  {
    City: "Dubai",
    Country: "UAE",
    Latitude: 25.2048,
    Longitude: 55.2708,
    similarity: 2.7,
    Response: "Shopping at luxurious malls and enjoying the desert safaris.",
  },
  {
    City: "Moscow",
    Country: "Russia",
    Latitude: 55.7558,
    Longitude: 37.6173,
    similarity: 1.9,
    Response: "Exploring Red Square and trying local Russian cuisine.",
  },
  {
    City: "Rome",
    Country: "Italy",
    Latitude: 41.9028,
    Longitude: 12.4964,
    similarity: 2.2,
    Response: "Admiring ancient ruins and enjoying Italian gelato.",
  },
  {
    City: "Istanbul",
    Country: "Turkey",
    Latitude: 41.0082,
    Longitude: 28.9784,
    similarity: 1.7,
    Response:
      "Exploring the bazaars and soaking up the history at Hagia Sophia.",
  },
  {
    City: "Bangkok",
    Country: "Thailand",
    Latitude: 13.7563,
    Longitude: 100.5018,
    similarity: 2.0,
    Response: "Enjoying street food and visiting vibrant night markets.",
  },
  {
    City: "Toronto",
    Country: "Canada",
    Latitude: 43.6532,
    Longitude: -79.3832,
    similarity: 2.1,
    Response: "Strolling through Kensington Market or catching a hockey game.",
  },
  {
    City: "Los Angeles",
    Country: "USA",
    Latitude: 34.0522,
    Longitude: -118.2437,
    similarity: 2.5,
    Response: "Hiking up to the Hollywood sign or relaxing on Venice Beach.",
  },
];
