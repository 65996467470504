import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LinearProgressBar(
  props: LinearProgressProps & { label: string; value: number },
) {
  return (
    <Box sx={theme=>({display: "flex", alignItems: "center", width: "100%" , paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2)})}>
        <Box sx={{ width: "100%", mr: 1}}>
        <Typography
          variant="body2"
          sx={theme => ({ opacity: 0.7, color: theme.palette.getContrastText(theme.palette.primary.main) })}
        >
          {props.label}
        </Typography>
        <LinearProgress color="secondary" sx={{color: "red"}} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={theme=>({ opacity: 0.7, color: theme.palette.getContrastText(theme.palette.primary.main) })}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
