export type City = {
  City: string;
  Country: string;
};

export type CityGeo = City & {
  Latitude: number;
  Longitude: number;
};

export type CityResponseScored = City & {
  similarity: number;
  Response: string;
};

export type CityGeoWithResponseScored = CityResponseScored & CityGeo;

export declare type UserInfo = { age: number; gender?: Gender; city: City };

type ViewBoxValue = number;

export type ViewBox = {
  x: ViewBoxValue;
  y: ViewBoxValue;
  width: ViewBoxValue;
  height: ViewBoxValue;
};

export enum Gender {
  Female = "female",
  Male = "male",
  NonBinary = "non-binary",
  NO_RESPONSE = "no response",
}

export type FormValues = {
  question?: string;
  answer?: string;
  gender?: Gender;
  age?: number;
  city?: City | string;
};

export type ValidFormValues = {
  question: string;
  answer: string;
  gender?: Gender;
  age: number;
  city: City;
};

export type Updaters = {
  updateAnswer: (a: string) => Promise<void>;
  updateQuestion: (q: string, a: string) => Promise<void>;
  addCity: (c: string) => Promise<CityGeoWithResponseScored>;
  addCities: () => Promise<void>;
};

export class FormError extends Error {
  userMessage: string;
  constructor(message: string, userMessage: string) {
    super(message);
    this.name = "FormError";
    this.userMessage = userMessage;
  }
}
export class VersionError extends Error {
  name = "VersionError";
}

export enum AppStatus {
  LOADING_FIRST_CITIES = "LOADING_FIRST_CITIES",
  UPDATING_QA = "UPDATING_QA",
  INITIAL_FORM_ACTIVE = "INITIAL_FORM_ACTIVE",
  UPDATE_FORM_ACTIVE = "UPDATE_FORM_ACTIVE",
  MAP_ACTIVE = "MAP_ACTIVE",
}

export enum FormStatus {
  LOADING = "LOADING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum Anchor {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_LEFT = "bottom-left",
}

export type ViewProperties = {
  isLandscape: boolean;
  fullScreen: boolean;
  isTouchDevice: boolean;
  isSmallHeight: boolean;
};
