import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, LinearProgress } from "@mui/material";
import { ValidFormValues, UserInfo } from "../../types";
import { useRef, useEffect } from "react";
import { lookupCity } from "../../logic/api";
import useViewProperties from "../../styles/useViewProperties";
import QuestionInput from "./QuestionInput";
import GenderInput from "./GenderInput";
import AgeInput from "./AgeInput";
import AnswerInput from "./AnswerInput";
import { City, FormValues, Gender } from "../../types";
import UserCityInput from "./UserCityInput";
import { Paper, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";


export default function FormDialog({
  onSubmit,
  userInfo,
  loading,
  question,
  answer,
  onClose,
}: {
  onSubmit: (formValues: ValidFormValues) => void;
  userInfo?: UserInfo;
  loading: boolean;
  question?: string;
  answer?: string;
  onClose?: () => void;
}) {
  const { fullScreen } = useViewProperties();

 
  const values = useRef<FormValues>({
    gender: userInfo?.gender,
    age: userInfo?.age,
    question,
    answer,
    city: userInfo?.city,
  });

  const handleClose = () => {
    if (loading) {
      return;
    }

    const { question, answer, gender, age, city } = values.current;

    if (!question || !answer || !age || !city) {
      const missingFields = [];
      if (!question) missingFields.push("Question");
      if (!answer) missingFields.push("Answer");
      if (!age) missingFields.push("Age");
      if (!city) missingFields.push("City");

      const missingFieldsText = missingFields.join(", ");
      alert(`Please fill out all fields: ${missingFieldsText}`);
      return;
    }

    if (typeof city === "string") {
      lookupCity(city)
        .then((cleanedCity) => {
          values.current.city = cleanedCity;
          onSubmit({
            question,
            answer,
            gender,
            age,
            city: cleanedCity,
          } as ValidFormValues);
        })
        .catch((e) => {
          alert(
            `Couldn't find city ${city}. Format should be "City, Country". Or choose the nearest major city from the dropdown.`,
          );
        });

      return;
    }

    onSubmit({
      question,
      answer,
      gender,
      age,
      city: city as City,
    } as ValidFormValues);
  };

  const skipUserInfo = userInfo !== undefined;

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{
          component: "form",

          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            handleClose();
          },
        }}
      >
        <DialogTitle>Find your people</DialogTitle>
        <DialogContent>
          
          <Container maxWidth={false}>
            <Stack spacing={2} direction="column">
              {!skipUserInfo && (
                <Paper elevation={5} sx={{ padding: 3 }}>
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ width: "100%" }}>
                      Who are you?
                    </Typography>

                    <Stack
                      direction="row"
                      useFlexGap
                      spacing={2}
                      sx={{ flexWrap: "wrap", gap: 2 }}
                    >
                      <GenderInput
                        initialValue={values.current.gender}
                        updateValue={(val: Gender | undefined) =>
                          (values.current.gender = val)
                        }
                      />
                      <AgeInput
                        initialValue={values.current.age}
                        updateValue={(val: number | undefined) =>
                          (values.current.age = val)
                        }
                      />
                      <UserCityInput
                        updateValue={(val: City | string | undefined) =>
                          (values.current.city = val)
                        }
                      />
                    </Stack>
                  </Stack>
                </Paper>
              )}
              <Paper elevation={5} sx={{ padding: 3 }}>
                <Stack spacing={2} direction="column">
                  <QuestionInput
                    initialValue={values.current.question}
                    updateValue={(val: string | undefined) =>
                      (values.current.question = val)
                    }
                  />
                  <AnswerInput
                    initialValue={values.current.answer}
                    updateValue={(val: string | undefined) =>
                      (values.current.answer = val)
                    }
                  />
                </Stack>
              </Paper>

              <LoadingButton loading={loading} variant="contained" type="submit">
                Submit
              </LoadingButton>
              
            </Stack>
          </Container>
        </DialogContent>

        <DialogActions>

          {onClose && (
            <Button disabled={loading} type="button" onClick={onClose}>
              Close
            </Button>
          )}
          
        </DialogActions>
        {loading && <LinearProgress sx={{ width: "100%", height: 10 }} />}
      </Dialog>
    </>
  );
}
