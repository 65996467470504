import { CityGeoWithResponseScored } from "../../types";
import { getCityResponseId } from "../../logic/api";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { ScaleDiverging, ScaleSequential } from "d3";
import Stack from "@mui/material/Stack";
import useViewProperties from "../../styles/useViewProperties";
import { useEffect, useRef, useState } from "react";

const CityList = ({
  cities,
  selectCity,
  color,
  horizontal = false,
}: {
  cities: CityGeoWithResponseScored[];
  selectCity: (city: CityGeoWithResponseScored | null) => void;
  color: ScaleSequential<string, never> | ScaleDiverging<string>;
  horizontal?: boolean;
}) => {
  const { isLandscape, isTouchDevice } = useViewProperties();
  const listRef = useRef<HTMLDivElement | null>(null); // Reference to the list

  const [scrollingCity, setScrollingCity] =
    useState<CityGeoWithResponseScored | null>(null);
  useEffect(() => {
    if (isTouchDevice) {
      selectCity(scrollingCity);
    }
  }, [scrollingCity]);

  // Handle scroll event and determine which city is currently in view
  const handleScroll = () => {
    
    if (listRef.current) {
      const listLow = listRef.current[isLandscape ? "scrollTop" : "scrollLeft"];
      const listHigh = listLow + listRef.current[isLandscape ? "clientHeight" : "clientWidth"];

      const listItems = listRef.current.children; // All list items

      // Find the list item that is closest to the current scroll position
      let maxIndex = -1;
      let maxVal = 0;
      for (let i = 0; i < listItems.length; i++) {
        const listItem = listItems[i] as HTMLLIElement;

        const low = listItem[!isLandscape ? "offsetLeft" : "offsetTop"];
        const high = low + listItem[!isLandscape ? "offsetWidth" : "offsetHeight"];
        const window = Math.min(high, listHigh) - Math.max(low, listLow);

        if (
          (low >= listLow && low <= listHigh) ||
          (high >= listLow && high <= listHigh)
        ) {
          if (window > maxVal) {
            maxIndex = i;
            maxVal = window;
          }
        }
      }
      setScrollingCity(cities[maxIndex]);
    }
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (cities.length === 0){return}

    if (listElement) {
      // Attach scroll listener
      listElement.addEventListener("scroll", handleScroll);

      // Cleanup the listener on unmount
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [cities, isLandscape]);

  return (
    <List
      ref={listRef}
      flexWrap={"nowrap"}
      component={Stack}
      direction={isLandscape ? "column" : "row"}
      sx={{
        overflow: "auto",
        ...(isLandscape && {
          maxHeight: "100%",
          overflowY: "auto",
        }),
      }}
      spacing={1}
    >
      {cities.map((city) => (
        <ListItem
          key={getCityResponseId(city)}
          sx={(theme) => ({
            cursor: "default",
            transition: "background-color 2s ease-in-out",
            backgroundColor: color(city.similarity),
            border: `3px solid ${isTouchDevice && scrollingCity === city ? "yellow" : "transparent"}`,
            color: theme.palette.getContrastText(color(city.similarity)),
            ...(isTouchDevice
              ? {}
              : {
                  "&:hover": {
                    borderColor: "yellow",
                  },
                }),
            flexShrink: 0,
            width: !isLandscape ? "fit-content" : "100%",
          })}
          {...(!isTouchDevice && {
            onMouseOver: () => selectCity(city),
            onMouseLeave: () => selectCity(null),
          })}
        >
          <ListItemText
            primary={city.Response}
            secondary={`${city.City}, ${city.Country}`}
            sx={(theme) => ({
              color: theme.palette.getContrastText(color(city.similarity)),
              "& .MuiListItemText-secondary": {
                color: theme.palette.getContrastText(color(city.similarity)),
              },
            })}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CityList;
