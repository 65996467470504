import { ViewBox } from "../types";

export function linspace(start: number, stop: number, num: number = 50) {
  const step = (stop - start) / (num - 1);
  return Array.from({ length: num }, (_, i) => start + step * i);
}
export const randomChoice = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const isTypeCity = (data: any): boolean => {
  return (
    typeof data === "object" &&
    data !== null &&
    "City" in data &&
    "Country" in data
  );
};

export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const approxEqual = (a: number, b: number, epsilon: number = 0.01) => {
  return Math.abs(a - b) < epsilon;
};

export const viewBoxEqual = (a: ViewBox, b: ViewBox) => {
  return (
    approxEqual(a.x, b.x) &&
    approxEqual(a.y, b.y) &&
    approxEqual(a.width, b.width) &&
    approxEqual(a.height, b.height)
  );
};
