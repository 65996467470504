import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import Typography from "@mui/material/Typography";
import { Button, Container, IconButton, Paper, Stack, SxProps, Theme } from "@mui/material";
import { UserInfo } from "../../types";
import AppBar from "@mui/material/AppBar";
import useViewProperties from "../../styles/useViewProperties";
import SwipeableEdgeDrawer from "./SwipeableDrawer";
import { SWIPEABLE_DRAWER_HEIGHT } from "../../logic/constant";
import CloseIcon from "@mui/icons-material/Close";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";

import InfoIcon from '@mui/icons-material/Info';
import { Popper } from '@mui/base/Popper';
import zIndex from "@mui/material/styles/zIndex";


const Header = ({text, loadBar, subHeader, toggleDrawer}: {text: string, loadBar: React.ReactNode, subHeader: React.ReactNode, toggleDrawer: React.ReactNode}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return <Stack
    flexWrap={"wrap"}
    justifyContent={"space-between"}
    alignItems={"space-between"}
    flexDirection={"row"}
    useFlexGap              
    spacing={1}
    
  >

 
  
  

  {loadBar}

  <IconButton onClick={handleClick} sx={theme=>({color: theme.palette.secondary.main})}>
    {open? <CloseIcon/> : <InfoIcon/>}
  </IconButton>

  {toggleDrawer}
  
  <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex:9999}}>
      {subHeader}
  </Popper>
  <Typography
    variant="h5"
    align="left"
    sx={(theme) => ({ flex: 1, padding: theme.spacing(1), minWidth: 120 })}
  >
    {text}
  </Typography>
  </Stack>
}

const SubHeader =({text1, text2, details}: {text1: string | React.ReactNode, text2: string | React.ReactNode, details: React.ReactNode}) =>{


  return <Paper
            elevation={4}
            sx={(theme) => ({
              padding: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            })}
          >
          

        <Typography variant="subtitle1" align="left" sx={{width: "100%"}}>
          {text1}
        </Typography>

        <Typography
          textAlign={"left"}
          variant="subtitle2"
          sx={(theme) => ({ pointerEvents: "none", userSelect: "none" })}
        >
          {text2}
        </Typography>
        
        {details}
      </Paper>


}

function InfoPanel({
  loadBar,
  drawerContent,
  mainContent,
  subHeader,
  onDrawerClose,
  question,
  answer,
  userInfo,
}: PropsWithChildren<{
  loadBar: React.ReactNode;
  drawerContent: React.ReactNode;
  mainContent: React.ReactNode;
  subHeader?: React.ReactNode;
  question: string;
  answer: string;
  userInfo: UserInfo;
  onDrawerClose: ()=>void
}>) {
  let { isLandscape, isTouchDevice, isSmallHeight } = useViewProperties();
  const [drawerSize, setDrawerSize] = useState(0);
  const drawerOpen = useRef<boolean>(false)
  const [open, setOpen] = useState(false);
  useEffect(()=>{
    if (!open && drawerOpen.current){onDrawerClose()}
    drawerOpen.current = open
  }, [open])


  // becuse of the border radius of the drawer, we want it to overlap the main content by a bit
  const adjustedSwipeableDrawerHeight = isTouchDevice
    ? SWIPEABLE_DRAWER_HEIGHT * 0.7
    : 0;

  const drawerRef = useRef<HTMLDivElement>(null);


  const verticalAppBar = false //isLandscape && isSmallHeight
  const bottomDrawer = isLandscape;

  useEffect(() => {
    const el = drawerRef.current;
    if (!el) {
      return;
    }
    const paperEl = el.querySelector(".MuiPaper-root") as HTMLElement;
    const totalSize =
      paperEl[bottomDrawer ? "clientWidth" : "clientHeight"] + adjustedSwipeableDrawerHeight;

    totalSize && totalSize !== drawerSize && setDrawerSize(totalSize);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const totalSize =
          entry.contentRect[bottomDrawer ? "width" : "height"] + adjustedSwipeableDrawerHeight;
        if (totalSize !== drawerSize) {
          setDrawerSize(totalSize);
        }
      }
    });
    resizeObserver.observe(paperEl);

    return () => {
      resizeObserver.disconnect();
    };
  }, [drawerRef.current, bottomDrawer]);

  
  return (
      <Stack flex={1} flexDirection={!verticalAppBar ? "column" : "row"} flexWrap={"nowrap"} alignItems={"stretch"}
        sx={{[isLandscape ? "ml" : "mb"]: (open ? drawerSize : adjustedSwipeableDrawerHeight) + "px"}}
      >
      <div style={{flexBasis: 0, flexGrow: 0, flexShrink: 0}}>
      <AppBar position={"relative"} sx={{zIndex: 100, ...(verticalAppBar ? {width: "fit-content"} : {width: "100%"})}}>
         
          <Header 
            toggleDrawer={
              <IconButton sx={theme=>({color: theme.palette.secondary.main})} onClick={()=>setOpen(o=>!o)}>
                {open ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
              </IconButton>
            }
            loadBar={loadBar} 
            text={question}
            subHeader={
              <SubHeader
              text1={<>You: <span style={{ fontStyle: "italic" }}>{answer}</span></>}
              text2={`Other ${userInfo.age}-year-old ${userInfo.gender ? ` ${userInfo.gender}` : ""}s around the world answer...`}
              details={subHeader}
          />}
          />


      </AppBar>
      </div>
      <SwipeableEdgeDrawer
        open={open}
        setOpen={setOpen}
        ref={drawerRef}
        content={drawerContent}
      />
      <div style={{flex: 1}}> {mainContent} </div>
      
    </Stack>
  );
}

export default InfoPanel;
