import { getCurrMonthName } from "./date";

export const PROD = process.env.REACT_APP_PROD === "true";
export const MOCK_DATA = process.env.REACT_APP_MOCK_DATA === "true";

export const QUESTION_SUGGESTIONS = [
  "What do you do for fun?",
  `Do you spend a lot of time outside in ${getCurrMonthName()}?`,
];
export const TOTAL_BATCHES = 4;

// export const TOTAL_BATCHES = 1;
export const VIEW_BOX_ANIMATION_DURATION = 500;

export const SWIPEABLE_DRAWER_HEIGHT = 20;
