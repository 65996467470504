import CityInput from "./CityInput";
import worldCities from "../../data/world-cities.json";
import { City } from "../../types";

const UserCityInput = ({
  updateValue,
}: {
  updateValue: (val: City | string | undefined) => void;
}) => {
  const cities = worldCities as City[];

  return (
    <CityInput
      cities={cities}
      autoSelect
      onSelect={(city: City | string | undefined) => {
        updateValue(city);
      }}
      instructions="What's your home city?"
    />
  );
};

export default UserCityInput;
