import {
  geoNaturalEarth1,
  GeoProjection,
  ScaleSequential,
  extent,
  geoTransverseMercator,
  ScaleDiverging,
} from "d3";
import { CityGeoWithResponseScored } from "../../types";
import CountryMap from "./CountryMap";
import { useMemo, useRef, useState, useEffect } from "react";
import CityMarker from "./CityMarker";
import ColorLegend from "./ColorLegend";
import { getCityResponseId } from "../../logic/api";
import { ViewBox } from "../../types";
import { Container } from "@mui/material";
import PanningSVG from "./PanningSVG";
import { useMediaQuery } from "@mui/material";
import { SWIPEABLE_DRAWER_HEIGHT } from "../../logic/constant";

const getProjection = (
  isLandscape: boolean,
  width: number,
  height: number,
): GeoProjection => {
  if (isLandscape) {
    return geoNaturalEarth1().fitExtent(
      [
        [0, 0],
        [width, height / 2],
      ],
      { type: "Sphere" },
    );
  } else {
    return geoTransverseMercator().fitExtent(
      [
        [0, 0],
        [width, height],
      ],
      { type: "Sphere" },
    )
  }
};

const getTargetViewBox = (
  cityCoords: [number, number][],
  buffer: number,
): ViewBox => {
  const [left, right] = extent(cityCoords, (d) => d[0]) as [number, number];
  const [top, bottom] = extent(cityCoords, (d) => d[1]) as [number, number];
  return {
    x: left - buffer,
    y: top - buffer,
    width: right - left + 2 * buffer,
    height: bottom - top + 2 * buffer,
  };
};

const WorldMap = ({
  citiesEnriched,
  selected,
  color,
}: {
  citiesEnriched: CityGeoWithResponseScored[];
  selected: CityGeoWithResponseScored | null;
  color: ScaleSequential<string, never> | ScaleDiverging<string>;
}) => {
  const isLandscape = useMediaQuery("(min-aspect-ratio: 1/1)");

  const containerRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({
    width: containerRef.current?.offsetWidth ?? window.innerWidth,
    height: containerRef.current?.offsetHeight ?? window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: containerRef.current?.offsetWidth ?? window.innerWidth,
        height: containerRef.current?.offsetHeight ?? window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial dimensions

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef]);

  const { width, height } = dimensions;

  const projection = useMemo<GeoProjection>(
    () => getProjection(isLandscape, width, height),
    [isLandscape, width, height],
  );

  const cityCoords = citiesEnriched.map((city) =>
    projection
      ? (projection([city.Longitude, city.Latitude]) ?? [0, 0])
      : [0, 0],
  ) as [number, number][];

  const targetViewBox = cityCoords.length > 0 ? getTargetViewBox(cityCoords, 15) : {x: 0, y: 0, width, height};

  const selectedCoords = selected
    ? (projection([selected.Longitude, selected.Latitude]) ?? [0, 0])
    : null;

  const r = Math.round(Math.min(width, height) / 110);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        marginBottom: SWIPEABLE_DRAWER_HEIGHT,
      }}
    >
      <PanningSVG target={targetViewBox}>
        <CountryMap projection={projection} />

        {citiesEnriched.map((city, i) => (
          <CityMarker
            key={getCityResponseId(city)}
            city={city}
            x={cityCoords[i][0]}
            y={cityCoords[i][1]}
            r={r}
            selected={false}
            color={color}
          />
        ))}
        {selected && selectedCoords && (
          <CityMarker
            key={getCityResponseId(selected)}
            city={selected}
            x={selectedCoords[0]}
            y={selectedCoords[1]}
            r={r}
            selected={true}
            color={color}
          />
        )}
      </PanningSVG>
    </div>
  );
};

export default WorldMap;
