import FormDialog from "./components/form/FormDialog";
import { useEffect, useRef, useState } from "react";
import { UserInfo, ValidFormValues } from "./types";
import "./styles/styles.css";
import { MOCK_DATA, PROD } from "./logic/constant";
import LoadedApp from "./components/LoadedApp";
import { FormStatus } from "./types";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { ping } from "./logic/api";
import { Stack } from "@mui/material";

const USER_INFO_KEY = "userInfo";

let init_user_info: UserInfo | undefined = undefined;
let init_answer: string = "";
let init_form_status: FormStatus = FormStatus.ACTIVE;

if (!PROD && MOCK_DATA) {
  const mockData = require("./tests/mock-data");
  init_user_info = mockData.EXAMPLE_USER_INFO;
  init_answer = "I like to travel";
  init_form_status = FormStatus.INACTIVE;
} else {
  const storedUserInfo = localStorage.getItem(USER_INFO_KEY);
  if (storedUserInfo) {
    init_user_info = JSON.parse(storedUserInfo);
  }
}

let theme = createTheme({
  typography: {
    fontSize: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 16,
        },
      },
    },
  },
});

theme = createTheme(theme, {
  palette: {
   
    primary: theme.palette.augmentColor({
      color: {
        main: '#102542',
      },
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: "#f87060",
      },
    }),
    
    background: {
      paper: "#cdd7d6",
      default: "white"
    },
  },
});

// theme = responsiveFontSizes(theme);

function App() {
  // data
  const [question, setQuestion] = useState<string>("What do you like to do?");
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(
    init_user_info,
  );
  const pinged = useRef(false);
  useEffect(() => {
    if (!pinged.current) {
      ping();
      pinged.current = true;
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
    }
  }, [userInfo]);
  const [answer, setAnswer] = useState<string>(init_answer);
  const [formStatus, setFormStatus] = useState<FormStatus>(init_form_status);

  const onSubmit = (formValues: ValidFormValues) => {
    setUserInfo({
      gender: formValues.gender,
      age: formValues.age,
      city: formValues.city,
    });
    setQuestion(formValues.question);
    setAnswer(formValues.answer);

    setFormStatus(FormStatus.LOADING);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction={"column"}
        flex={1}
        alignItems={"stretch"}
        flexWrap={"nowrap"}
        id="app"
        sx={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        {question && answer && userInfo && (
          <LoadedApp
            question={question}
            answer={answer}
            user={userInfo}
            setQuestion={(question: string) => setQuestion(question)}
            setAnswer={(answer: string) => setAnswer(answer)}
            setFormStatus={setFormStatus}
          />
        )}

        {(formStatus === FormStatus.ACTIVE ||
          formStatus === FormStatus.LOADING) && (
          <FormDialog
            onSubmit={onSubmit}
            userInfo={userInfo}
            loading={formStatus === FormStatus.LOADING}
          />
        )}
      </Stack>
    </ThemeProvider>
  );
}

export default App;
