import TextField from "@mui/material/TextField";
import { FormControl, FormHelperText, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
type Value = string;

const AnswerInput = ({
  updateValue,
  initialValue,
}: {
  updateValue: (val: Value | undefined) => void;
  initialValue: Value | undefined;
}) => {
  const [value, setValue] = useState<Value | undefined>(initialValue ?? "");

  const minLength = 5;

  const tooShort = (value ?? "").length < minLength;

  useEffect(() => {
    updateValue(tooShort ? undefined : (value as Value));
  }, [value, updateValue, tooShort]);

  return (
    <FormControl fullWidth sx={{ flexBasis: "100%" }}>
      <TextField
        value={value}
        label="Your Answer"
        onChange={(e) => setValue(e.target.value)}
        multiline
        placeholder="I like to go swimming"
        slotProps={{
          input: {
            endAdornment: (
              <IconButton size="small" onClick={() => setValue("")}>
                <ClearIcon />
              </IconButton>
            ),
          },
        }}
      />
      {tooShort && <FormHelperText>Min {minLength} characters</FormHelperText>}
    </FormControl>
  );
};

export default AnswerInput;
