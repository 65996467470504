import { City, CityResponseScored } from "../types";
import { PROD } from "./constant";

export const getCityId = (city: City): string => `${city.City}-${city.Country}`;

export const getCityResponseId = (city: CityResponseScored): string =>
  `${city.City}-${city.Country}-${city.Response}`;

export const getUniqueCities = (cities: City[]): City[] => {
  return Array.from(
    new Set(
      cities.map((city) =>
        JSON.stringify({ City: city.City, Country: city.Country }),
      ),
    ),
  ).map((str) => JSON.parse(str));
};

export const filterCities = <T extends City>(
  cities: T[],
  include: City[],
): T[] => {
  const includeIds = new Set(include.map((c) => getCityId(c)));
  return cities.filter((city) => includeIds.has(getCityId(city)));
};

class FetchError extends Error {}

// set up domain
let DOMAIN: string;
if (!PROD) {
  DOMAIN = "http://127.0.0.1:8000";
} else {
  DOMAIN =
    process.env.REACT_APP_BACKEND_PROD_DOMAIN ??
    "https://ask-around-backend.onrender.com";
}

DOMAIN = DOMAIN + "/api";

const request = async (
  endpoint: string,
  data?: object,
  attempts: number = 0,
): Promise<object> => {
  const url = DOMAIN + endpoint;

  console.log("Requesting", url);
  try {
    const res = await fetch(url, {
      method: data == null ? "GET" : "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": PROD
          ? (process.env.REACT_APP_FRONTEND_PROD_DOMAIN ??
            "https://ask-around-frontend.onrender.com")
          : "http://localhost:3000",
      },
      body: data == null ? undefined : JSON.stringify(data),
      credentials: "include",
      mode: "cors",
    });

    if (Math.floor(res.status / 100) === 2) {
      const data = await res.json();
      return data as [object]; // Return the JSON data if status is 200
    } else {
      throw new FetchError(`Request failed with status ${res.status}`);
    }
  } catch (error) {
    if (attempts < 2) {
      console.error("Failed attempt, trying again " + endpoint);
      return await request(endpoint, data, attempts + 1);
    }
    throw new FetchError(`Request failed ${error}`);
  }
};

export const post = async (
  endpoint: string,
  data: object,
  attempts: number = 0,
): Promise<[object]> => {

  return request(endpoint, data, attempts) as Promise<[object]>;
};

export const lookupCity = async (rawCity: string): Promise<City> => {
  const res = await request(`/city/lookup?city=${encodeURIComponent(rawCity)}`);
  return res as City;
};

export const ping = async () => {
  request("/ping").then(() => {
    console.log("pinged");
  }).catch((error) => {
    console.error("Failed to ping", error);
    alert("Failed to connect to server");
  });
};
