import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import { ViewBox } from "../../types";
import { viewBoxEqual } from "../../logic/helper";
import { VIEW_BOX_ANIMATION_DURATION as DURATION } from "../../logic/constant";
import { useTheme } from "@mui/material/styles";


const getDelta = (diff: number, progress: number): number => {
  const minDelta = 1;

  const progressDelta = Math.abs(progress * diff);

  const delta = Math.max(progressDelta, minDelta);

  return delta * Math.sign(diff);
};

const PanningSVG = ({
  target,
  children,
}: PropsWithChildren<{ target: ViewBox }>) => {
  const [currentViewBox, setCurrentViewBox] = useState<ViewBox>(target);
  const animationRef = useRef<number>();
  const theme = useTheme();
  useEffect(() => {
    const startTime = Date.now();

    const animate = () => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / DURATION, 1);

      if (elapsedTime >= DURATION || viewBoxEqual(currentViewBox, target)) {
        return;
      }

      const diff = {
        x: target.x - currentViewBox.x,
        y: target.y - currentViewBox.y,
        width: target.width - currentViewBox.width,
        height: target.height - currentViewBox.height,
      };

      const newViewBox = {
        x: currentViewBox.x + getDelta(diff.x, progress),
        y: currentViewBox.y + getDelta(diff.y, progress),
        width: currentViewBox.width + getDelta(diff.width, progress),
        height: currentViewBox.height + getDelta(diff.height, progress),
      };

      setCurrentViewBox(newViewBox);

      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);

    // Cleanup function to cancel animation if component unmounts or target changes
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [target.x, target.y, target.width, target.height]);

  const viewBoxString = `${Math.round(currentViewBox.x)} ${Math.round(currentViewBox.y)} ${Math.round(currentViewBox.width)} ${Math.round(currentViewBox.height)}`;

  return (
    <svg
      style={{
        position: "absolute",
        zIndex: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.default,
        
      }}
      viewBox={viewBoxString}
    >
      {children}
    </svg>
  );
};

export default PanningSVG;
