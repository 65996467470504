import { CSSProperties, PropsWithChildren } from "react";
import { Anchor } from "../types";

const AnchoredBox = ({
  x,
  y,
  anchor,
  children,
  style,
}: PropsWithChildren<{
  x: number;
  y: number;
  anchor: Anchor;
  style: CSSProperties;
}>) => {
  const getStyle = () => {
    const baseStyle: CSSProperties = {
      position: "absolute",
      zIndex: 9999,
      ...style,
    };
    switch (anchor) {
      case Anchor.TOP_LEFT:
        return { ...baseStyle, left: `${x}px`, top: `${y}px` };
      case Anchor.TOP_RIGHT:
        return { ...baseStyle, right: `calc(100% - ${x}px)`, top: `${y}px` };
      case Anchor.BOTTOM_RIGHT:
        return {
          ...baseStyle,
          right: `calc(100% - ${x}px)`,
          bottom: `calc(100% - ${y}px)`,
        };
      case Anchor.BOTTOM_LEFT:
        return {
          ...baseStyle,
          left: `${x}px`,
          bottom: `calc(100% - ${y}px)`,
        };
      default:
        return { ...baseStyle, left: `${x}px`, top: `${y}px` };
    }
  };

  return <div style={getStyle() as CSSProperties}>{children}</div>;
};

export default AnchoredBox;
