import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { FormHelperText } from "@mui/material";
import { QUESTION_SUGGESTIONS } from "../../logic/constant";

type Value = string;

const QuestionInput = ({
  updateValue,
  initialValue,
}: {
  updateValue: (val: Value | undefined) => void;
  initialValue: Value | undefined;
}) => {
  const [value, setValue] = useState<Value | null>(
    initialValue ?? QUESTION_SUGGESTIONS[0],
  );

  const minLength = 5;

  const tooShort = (value ?? "").length < minLength;

  useEffect(() => {
    updateValue(tooShort ? undefined : (value as Value));
  }, [value, updateValue, tooShort]);

  return (
    <FormControl fullWidth sx={{ flexBasis: "100%" }}>
      <Autocomplete
        freeSolo
        disablePortal
        fullWidth
        onChange={(e, value) => setValue(value)}
        value={value}
        autoHighlight
        autoSelect
        options={QUESTION_SUGGESTIONS}
        renderInput={(params) => (
          <TextField {...params} label="Ask the people of the world" />
        )}
      />
      {tooShort && <FormHelperText>Min {minLength} characters</FormHelperText>}
    </FormControl>
  );
};

export default QuestionInput;
