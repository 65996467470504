import { useState, useEffect, useRef } from "react";
import { TOTAL_BATCHES } from "../logic/constant";
import LinearProgressBar from "./data-viz/LinearProgressBar";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { VersionError } from "../types";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Menu from "./general/Menu";
import EditIcon from '@mui/icons-material/Edit';

const LoadingCities = ({
  addCities,
  activateForm,
}: {
  addCities: () => Promise<void>;
  activateForm: () => void;
}) => {
  const inProgress = useRef(-1);

  const [batchesDone, setBatchesDone] = useState(0);

  useEffect(() => {
    if (batchesDone < TOTAL_BATCHES && inProgress.current !== batchesDone) {
      inProgress.current = batchesDone;
      console.log("Loading more cities", batchesDone);
      addCities()
        .then(() => {
          setBatchesDone((b) => b + 1);
        })
        .catch((err) => {
          console.error(err);

          if (!(err instanceof VersionError)) {
            alert("Failed to load cities");
            activateForm();
          } else {
            setBatchesDone(TOTAL_BATCHES);
          }
        });
    }
  }, [batchesDone]);

  const progress = (batchesDone / TOTAL_BATCHES) * 100;

  const done = batchesDone === TOTAL_BATCHES;

  const menuItems = [
    {
      icon: <EditNoteIcon />,
      text: "Update Q/A",
      onClick: () => {
        activateForm();
      },
    },
  ];

  if (done) {
    menuItems.push({
      icon: <AutorenewIcon />,
      text: "Generate more responses",
      onClick: () => {
        setBatchesDone(0);
      },
    });
  }

  const label = done ? "Paused generating" : "Generating more survey data...";
  return (
    <>
      {!done && (
        <LinearProgressBar
          id="loading-cities-progress"
          label={label}
          value={progress}
        />
      )}
      <Menu items={menuItems} icon={<EditIcon/>} />
    </>
  );
};

export default LoadingCities;
