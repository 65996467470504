import { CSSProperties } from "react";
import "../../styles/styles.css";
import AnchoredBox from "../AnchoredBox";
import { Anchor } from "../../types";

const HandwrittenFadingText = ({
  text,
  x,
  y,
  selected,
  anchor,
}: {
  text: string;
  x: number;
  y: number;
  selected: boolean;
  anchor: Anchor;
}) => {
  const words = text.split(" ");
  const lastNWords = 10;
  const fadeStartIndex = Math.max(0, words.length - lastNWords);

  const getDuration = (index: number, fadeStartIndex: number) => {
    return !selected ? (index - fadeStartIndex + 1) * 0.1 : 0;
  };

  const maxIndex = words.length - 1;
  const maxDuration = getDuration(maxIndex, fadeStartIndex);

  const styledWords = words.map((word, index) => {
    //   let opacity = index >= fadeStartIndex
    //     ? 1 - (index - fadeStartIndex + 1) / 8
    //     : 1;
    const opacity = 1;
    return (
      <span
        key={index}
        style={
          {
            opacity,
            "--final-opacity": opacity,
            animation: `
                fadeIn ${getDuration(index, fadeStartIndex)}s ease-in forwards
            `,
            fontWeight: "500",
          } as CSSProperties
        }
      >
        {word}{" "}
      </span>
    );
  });

  return (
    <AnchoredBox style={{ pointerEvents: "none" }} anchor={anchor} x={x} y={y}>
      <div
        style={
          {
            ...styles.container,
            position: "relative",
            animation: `setBackground ${maxDuration}s ${0}s forwards`,
          } as CSSProperties
        }
      >
        <p style={styles.text as CSSProperties}>{styledWords}</p>
      </div>
    </AnchoredBox>
  );
};

const styles = {
  container: {
    pointerEvents: "none",
    maxWidth: "600px",
    position: "absolute",
    margin: 0, // Remove margin to prevent shifting
    padding: 3,
  },
  text: {
    margin: 0,
    fontFamily: '"Indie Flower", cursive',
    lineHeight: "1.5",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    color: "#333",
  },
};
export default HandwrittenFadingText;
