import { useEffect, useRef, useState, memo } from "react";
import useSurveyCity from "../logic/useSurveyCity";
import {
  FormStatus,
  UserInfo,
  CityGeoWithResponseScored,
  City,
  FormError,
  ValidFormValues,
} from "../types";
import LoadingCities from "./LoadingCities";
import { getUniqueCities, filterCities } from "../logic/api";
import useColor from "./data-viz/useColor";
import CityList from "./data-viz/CityList";
import InfoPanel from "./data-viz/InfoPanel";
import WorldMap from "./data-viz/WorldMap";
import CityInput from "./form/CityInput";
import FormDialog from "./form/FormDialog";
import ColorLegend from "./data-viz/ColorLegend";
const LoadedApp = ({
  question,
  answer,
  user,
  setFormStatus,
  setAnswer,
  setQuestion,
}: {
  question: string;
  answer: string;
  user: UserInfo;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
  setAnswer: (answer: string) => void;
  setQuestion: (question: string) => void;
}) => {
  const [updateFormStatus, setUpdateFormStatus] = useState<FormStatus>(
    FormStatus.INACTIVE,
  );
  const [cities, { addCities, addCity, updateAnswer, updateQuestion }] =
    useSurveyCity({
      question,
      answer,
      user,
      setUpdateFormStatus,
      setAnswer,
      setQuestion,
    });

  const firstLoad = useRef(true);

  const cityCount = cities.length;

  const [selected, selectCity] = useState<CityGeoWithResponseScored | null>(
    null,
  );

  const mounting = useRef(true)
  useEffect(()=>{
    if (mounting.current){
      mounting.current = false
      addCities().then(()=>setFormStatus(FormStatus.INACTIVE));
    }
  }, [])
  // get color scale
  const [color, updateDomain] = useColor(cities);
  // udpate color
  useEffect(() => {
    updateDomain(cities);
  }, [cities, updateDomain]);

  const dedupCities: City[] = getUniqueCities(cities);

  const [filteredCity, setFilteredCity] = useState<City | undefined>(undefined);

  const citiesFiltered = filteredCity
    ? filterCities(cities, [filteredCity])
    : cities;

  useEffect(() => {
    if (cityCount > 0 && firstLoad.current) {
      firstLoad.current = false;
      setFormStatus(FormStatus.INACTIVE);
    }
  }, [cityCount, setFormStatus]);

  return <>{cities.length > 0 && 
            <InfoPanel
              answer={answer}
              userInfo={user}
              question={question}
              onDrawerClose={()=>selectCity(null)}
              loadBar={
                <>
                  <LoadingCities
                    addCities={addCities}
                    activateForm={() => setUpdateFormStatus(FormStatus.ACTIVE)}
                  />
                </>
              }
              subHeader={<ColorLegend color={color} />}
              drawerContent={
                <>
                  <CityInput
                    onSelect={(city: City | string | undefined) => {
                      if (typeof city === "string") {
                        return;
                      }
                      setFilteredCity(city);
                    }}
                    autoSelect={false}
                    cities={dedupCities}
                    addNewCity={(
                      rawCity: string,
                      onSuccess: (newCity: CityGeoWithResponseScored) => void,
                    ) => {
                      addCity(rawCity)
                        .then((newCity) => {
                          onSuccess(newCity);
                        })
                        .catch((error: FormError) => {
                          alert(error.userMessage);
                          console.error(error.message);
                        });
                    }}
                  />
                  <CityList
                    color={color}
                    cities={citiesFiltered}
                    selectCity={selectCity}
                  />
                </>
              }
              mainContent={
                <WorldMap
                  citiesEnriched={cities}
                  selected={selected}
                  color={color}
                />
              }
           />
      }
      {updateFormStatus !== FormStatus.INACTIVE && (
        <FormDialog
          onClose={() => setUpdateFormStatus(FormStatus.INACTIVE)}
          onSubmit={(formValues: ValidFormValues) => {
            if (
              formValues.answer !== answer &&
              formValues.question === question
            ) {
              updateAnswer(formValues.answer).catch((err) => {
                alert(err.userMessage);
                setUpdateFormStatus(FormStatus.INACTIVE);
              });
            } else if (formValues.question !== question) {
              updateQuestion(formValues.question, formValues.answer).catch(
                (err) => {
                  alert(err.userMessage);
                  setUpdateFormStatus(FormStatus.INACTIVE);
                },
              );
            } else {
              setUpdateFormStatus(FormStatus.INACTIVE);
              return;
            }
            setUpdateFormStatus(FormStatus.LOADING);
          }}
          userInfo={user}
          question={question}
          answer={answer}
          loading={updateFormStatus === FormStatus.LOADING}
        />
      )}
    </>
};

export default LoadedApp;
