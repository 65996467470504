import useMediaQuery from "@mui/material/useMediaQuery";
import { ViewProperties } from "../types";
import { useTheme } from "@mui/material/styles";

const useViewProperties = (): ViewProperties => {
  const theme = useTheme();
  const isLandscape = useMediaQuery("(min-aspect-ratio: 1/1)");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallHeight = useMediaQuery('(max-height: 600px)');

  const isCoarsePointer = useMediaQuery("(pointer: coarse)");
  const noHover = useMediaQuery("(hover: none)");

  const isTouchDevice = isCoarsePointer || noHover;

  return { isLandscape, fullScreen, isTouchDevice, isSmallHeight };
};
export default useViewProperties;
