import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Gender } from "../../types";
import { useEffect, useState } from "react";

const GenderInput = ({
  updateValue,
  initialValue,
}: {
  updateValue: (val: Gender | undefined) => void;
  initialValue: Gender | undefined;
}) => {
  const [value, setValue] = useState<Gender>(
    initialValue || Gender.NO_RESPONSE,
  );

  useEffect(() => {
    updateValue(value === Gender.NO_RESPONSE ? undefined : value);
  }, [value, updateValue]);

  return (
    <FormControl>
      <InputLabel id="gender-select-label">Gender</InputLabel>

      <Select
        id="gender-select"
        value={value}
        label="Gender"
        labelId="gender-select-label"
        onChange={(e) => setValue(e.target.value as Gender)}
        sx={{
          minWidth: 120, // Adjust the minimum width to ensure it contains the label
        }}
      >
        {Object.values(Gender).map((gender) => (
          <MenuItem key={gender} value={gender}>
            {gender.charAt(0).toUpperCase() + gender.slice(1).replace("-", " ")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenderInput;
