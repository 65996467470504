import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { SWIPEABLE_DRAWER_HEIGHT } from "../../logic/constant";
import useViewProperties from "../../styles/useViewProperties";
import { Box, ButtonBase } from "@mui/material";
import { forwardRef, useEffect } from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const Puller = styled("div")<{ isLandscape: boolean }>(
  ({ theme, isLandscape }) => ({
    width: isLandscape ? 6 : 30,
    height: isLandscape ? 30 : 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: "absolute",
    ...(isLandscape
      ? {
          top: "calc(50% - 15px)",
          right: 8,
        }
      : {
          top: 8,
          left: "calc(50% - 15px)",
        }),
    ...theme.applyStyles("dark", {
      backgroundColor: grey[900],
    }),
  }),
);

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const DrawerEdge = ({isLandscape, setOpen}: {isLandscape: boolean,  setOpen: React.Dispatch<React.SetStateAction<boolean>>} ) => {
  return <StyledBox
            id="swipeable-drawer-puller"
            sx={(theme) => ({
              position: "absolute",
              backgroundColor: theme.palette.primary.light,
              visibility: "visible",
              
              ...(isLandscape
                ? {
                    top: 0,
                    bottom: 0,
                    right: -SWIPEABLE_DRAWER_HEIGHT,
                    width: SWIPEABLE_DRAWER_HEIGHT,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  }
                : {
                    top: -SWIPEABLE_DRAWER_HEIGHT,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    right: 0,
                    left: 0,
                    height: SWIPEABLE_DRAWER_HEIGHT,
                  }),
            })}
          >
            <Puller
              isLandscape={isLandscape}
              onClick={() => setOpen((open) => !open)}
            />
          </StyledBox>
}


const SwipeableEdgeDrawer = forwardRef<
  HTMLDivElement,
  {
    content: React.ReactNode;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
>((props, ref) => {
  const { isLandscape, isTouchDevice } = useViewProperties();


  return (
    <>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      />

      <SwipeableDrawer
        id="swipeable-drawer"
        anchor={isLandscape ? "left" : "bottom"}
        open={props.open}
        allowSwipeInChildren={true}
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
        swipeAreaWidth={isTouchDevice ? SWIPEABLE_DRAWER_HEIGHT: 0 }
        disableSwipeToOpen={false}
        disableBackdropTransition={true}
        disableDiscovery={false}
        hideBackdrop={true}
        ref={ref}
        ModalProps={{
          keepMounted: true,
          sx: { pointerEvents: "none" },
        }}
        keepMounted={true}
        PaperProps={{
          sx: (theme) => ({
            pointerEvents: "all",
            ...(isLandscape && { maxWidth: "20%" }),
          }),
        }}
      >
        {isTouchDevice && <DrawerEdge setOpen={props.setOpen} isLandscape={isLandscape}/>}

        <StyledBox
          sx={(theme) => ({
            position: "relative",
            backgroundColor: theme.palette.background.default,
            ...(isLandscape && { maxHeight: "100%", paddingLeft: theme.spacing(0.5), paddingTop: theme.spacing(1) }),
            ...((!isTouchDevice || isLandscape)  && { paddingTop: theme.spacing(2) }),
          })}
        >
          {props.content}
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
});

export default SwipeableEdgeDrawer;
