import { ScaleDiverging, ScaleSequential } from "d3";
import { linspace } from "../../logic/helper";
import { useTheme } from "@mui/material/styles";
import useViewProperties from "../../styles/useViewProperties";
import { Typography } from "@mui/material";

const ColorLegend = ({
  color,
}: {
  color: ScaleSequential<string, never> | ScaleDiverging<string>;
}) => {
  const numStops = 10;

  const domain = color.domain();

  const lowDomain = domain[0];
  const highDomain = domain[domain.length - 1];

  const ticks = linspace(lowDomain, highDomain, numStops);

  const colorStops = ticks.map(color);

  const theme = useTheme();

  const { isLandscape } = useViewProperties();

  return (
    <div
      style={{
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        // height: 50,
        maxWidth: 300,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
      }}
    > 
      <Typography align="center" color="textPrimary">Similarity</Typography>
      <svg width="100%" height="20">
        <defs>
          <linearGradient
            id="colorLegendGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            {colorStops.map((stopColor, i) => (
              <stop
                key={i}
                offset={`${(i / (numStops - 1)) * 100}%`}
                style={{ stopColor }}
              />
            ))}
          </linearGradient>
        </defs>
        {/* Render a rectangle filled with the gradient */}
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          fill="url(#colorLegendGradient)"
        />
      </svg>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{ color: color(lowDomain) }}>Least</Typography>
        <Typography variant="body2" sx={{ color: color(highDomain) }}>Most</Typography>
      </div>
    </div>
  );
};

export default ColorLegend;
