import TextField from "@mui/material/TextField";
import { FormControl, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

const AgeInput = ({
  updateValue,
  initialValue,
}: {
  updateValue: (val: number | undefined) => void;
  initialValue: number | undefined;
}) => {
  const [value, setValue] = useState<number | null>(initialValue ?? 20);

  useEffect(() => {
    updateValue(value ?? undefined);
  }, [value, updateValue]);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Autocomplete
        autoHighlight
        autoSelect
        value={value}
        disablePortal
        options={Array.from(Array(86), (e, i) => i + 5)}
        getOptionLabel={(opt: number) => opt + ""}
        noOptionsText={"Invalid age"}
        onChange={(e, value) => setValue(value)}
        renderInput={(params) => (
          <TextField {...params} label="Age" type="number" />
        )}
      />
      {value == null && <FormHelperText>Required field</FormHelperText>}
    </FormControl>
  );
};

export default AgeInput;
