import { useRef, useEffect, LegacyRef } from "react";
import { geoPath, select, GeoProjection } from "d3";
import * as topojson from "topojson-client";
import * as worldData from "../../data/countries-50m.json";
import { Topology, GeometryCollection } from "topojson-specification";
import { Theme, useTheme } from "@mui/material/styles";

const draw = (gElement: SVGGElement, projection: GeoProjection, theme: Theme) => {
  const path = geoPath(projection);

  // Cast the world data as the correct Topology type
  const world = worldData as unknown as Topology<{
    countries: GeometryCollection;
  }>;

  const countries = topojson.feature(world, world.objects.countries);
  const countrymesh = topojson.mesh(
    world,
    world.objects.countries,
    (a, b) => a !== b,
  );

  const g = select(gElement);

  const countryColor = theme.palette.background.paper;
  g.selectAll("*").remove();

  // Add a path for each country and color it according te this data.
  g.selectAll("path")
    .data(countries.features)
    .join("path")
    .attr("fill", countryColor)
    .attr("opacity", 1)
    .attr("d", path);

  // // Add a white mesh.
  g.append("path")
    .datum(countrymesh)
    .attr("fill", "none")
    .attr("stroke", theme.palette.getContrastText(countryColor))
    .attr("opacity", 0.3)
    .attr("d", path);
};

const CountryMap = ({ projection }: { projection: GeoProjection }) => {
  const gRef = useRef<SVGGElement>();

  const id = "country-map";
  const theme = useTheme();


  useEffect(() => {
    if (!gRef.current || !projection) {
      return;
    }

    draw(gRef.current, projection, theme);
  }, [projection]);

  return <g ref={gRef as LegacyRef<SVGGElement>} id={id}></g>;
};
export default CountryMap;
