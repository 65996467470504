import { useMemo, useState } from "react";
import { CityResponseScored } from "../../types";
import {
  ScaleSequential,
  extent,
  scaleSequential,
  interpolateRdBu,
  scaleDiverging,
  interpolate,
  ScaleDiverging,
} from "d3";
import { useCallback } from "react";
import { useTheme } from "@mui/material/styles";

function createDivergingColorScale(
  light1: string,
  dark1: string,
  light2: string,
  dark2: string,
  domain: [number, number],
): ScaleDiverging<string> {
  // Create the diverging scale
  const midpoint = (domain[0] + domain[1]) / 2;
  const colorScale = scaleDiverging<string>()
    .domain([domain[0], midpoint, domain[1]]) // Diverging around the midpoint
    .interpolator(function (t: number) {
      if (t < 0.5) {
        // First half: interpolate from light1 to dark1
        return interpolate(dark1, light1)(t * 2) as unknown as string;
      } else {
        // Second half: interpolate from light2 to dark2
        return interpolate(light2, dark2)((t - 0.5) * 2) as unknown as string;
      }
    });

  return colorScale;
}

const useColor = (
  cities: CityResponseScored[],
): [
  ScaleSequential<string, never> | ScaleDiverging<string>,
  (c: CityResponseScored[]) => void,
] => {
  const [domain, setDomain] = useState<[number, number]>([0, 1]);
  const [min, max] = domain;
  const theme = useTheme();

  // const color = useMemo<ScaleSequential<string, never>>(
  //   () => scaleSequential([min, max], interpolateRdBu),
  //   [min, max],
  // );
  const color = createDivergingColorScale(
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.secondary.light,
    theme.palette.secondary.dark,
    domain,
  );

  const updateDomain = useCallback((cities: CityResponseScored[]) => {
    const scores = cities.map((e) => e.similarity);
    let [min, max] = extent(scores);
    let [minDomain, maxDomain] = domain;

    min != null &&
      max != null &&
      min !== minDomain &&
      max !== maxDomain &&
      setDomain([min, max]);
  }, []);

  return [color, updateDomain];
};

export default useColor;
